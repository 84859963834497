import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFullBlogAction } from '../reducers/newsSlice';
import { useNavigate } from 'react-router-dom';
function addBlogAction(dispatch, blog) {
    if (!blog.title || blog.title.length < 3) {
        alert("标题长度过短!")
        return;
    }
    if (blog.title.length > 30) {
        alert("标题长度过长!")
        return;
    }
    if (!blog.content || blog.content.length < 10) {
        alert("内容长度过短!")
        return;
    }
    if (blog.title.length > 4000) {
        alert("内容长度过长!")
        return;
    }

    if (blog.tags && blog.tags.length > 30) {
        alert("标签长度过长!")
        return;
    }

    dispatch(addFullBlogAction(blog));
}

const EditPost = (fblog) => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.news.status);
    const error = useSelector((state) => state.news.error);
    //todo: 加载一个博客
    console.log(fblog)
    const navigate = useNavigate();
    const titleRef = useRef(null);
    const contentRef = useRef(null);
    const tagsRef = useRef(null);

    const handleChange = () => {
        const title = titleRef.current.value;
        const content = contentRef.current.value;
        const tags = tagsRef.current.value;
        addBlogAction(dispatch, { title, content, tags })
    };
    useEffect(() => {
        // 在应用启动时执行的 action

    }, [dispatch]);
    if (error) {
        alert(error)
    }
    return (
        <div className='posteditor'>
            {(status === "loading") && <div className="loading-overlay">Loading...</div>}
            <div className='posteditor-title'>
                <span>标题：</span>
                <input type='text' ref={titleRef} className='' />
            </div>
            <div className='posteditor-content'>
                <textarea ref={contentRef} placeholder='请在这里添加内容' >
                </textarea>
            </div>
            <div className='posteditor-tags'>
                <span>标签</span> <input type='text' ref={tagsRef} className='' placeholder='标签' />
            </div>
            <div className='posteditor-actionbar'>
                <button className='btn btn-cancel' onClick={() => navigate("/admin/blog")}>取消</button>
                <button className='btn btn-save' onClick={handleChange}>保存</button>
            </div>
        </div>
    );
}

export default EditPost;