// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Post from './pages/Post';
import store from './reducers/store';
import { Provider } from 'react-redux';
import withPageLoad from './util/withPageLoad';
import Admin from './pages/Admin';
import NewsItemListSimple from './components/NewsItemListSimple';
import EditPost from './components/EditPost';
//包裹组件

const HPost = withPageLoad(Post);
const HHome = withPageLoad(Home);
const HAdmin = withPageLoad(Admin);
const HNewsItemListSimple = withPageLoad(NewsItemListSimple);
const HEditPost = withPageLoad(EditPost);
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<HHome />} />
          <Route path="/home" element={<HHome />} />
          <Route path="/blog/:bid/post/:pid/:tbid" element={<HPost />}>
            {/* <Route path=":postId" element={<PostDetail />}>
            <Route path="comments" element={<PostComments />} />
          </Route> */}
          </Route>
          <Route path="/admin/blog" element={<HAdmin />}>
            <Route path="list" element={<HNewsItemListSimple />} />
            <Route path="new" element={<HEditPost />} />
            <Route path="edit/:bid" element={<HEditPost />} />
          </Route>
        </Routes>
      </Router>
    </Provider >
  );
}
export default App;
