import { configureStore } from '@reduxjs/toolkit';
import navSlice from './navSlice';
import newsSlice from './newsSlice';
const store = configureStore({
    reducer: {
        nav: navSlice,
        news: newsSlice
    },
});

export default store;