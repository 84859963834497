import { useSelector } from 'react-redux';
function NavHeader() {
    const navItems = useSelector(state => state.nav.headItems) || [];
    const activeId = useSelector(state => state.nav.active) || 1;
    return (
        <div className="App">
            <header className="App-header">
                <nav>
                    <ul>
                        <li className="nav-logo"></li>
                        {navItems.map((item) => (
                            <li key={item.id} className={activeId === item.id ? "nav-active" : ""}>
                                <a href={item.url}>{item.desc}</a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </header>
        </div>
    );

}

export default NavHeader;
