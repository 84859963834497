
import config from '../config'
export const baseUrl = config.apiBaseUrl;
const getHeader = {
    method: 'GET',
    mode: 'cors', // 允许跨域请求
    headers: {
        'Content-Type': 'application/json',
        'token': config.getToken()
    }
}
const postHeader = { ...getHeader, method: "POST" };
export async function getBlogById(blogId) {
    const res = await fetch(baseUrl + "blog/get/" + blogId)
    return res;
};
export async function getBlogTopNew() {
    const res = await fetch(baseUrl + "blog/getTop")
    return res;
};

export async function getBlogByPage(pageIndex, pageSize) {
    const res = await fetch(baseUrl + "blog/page/" + pageIndex + "?pageSize=" + pageSize, getHeader)
    return res;
}
///添加文章和内容
export async function addFullBlog({ title, tbid, pid, content, summary, creator, tags }) {
    const res = await fetch(baseUrl + "fblog/add", {
        ...postHeader, body: JSON.stringify({
            title,
            tbid,
            postId: pid,
            summary,
            content,
            creator,
            tags
        })
    })
    return res;
}
export async function getFullBlog(bid, tbid, postId) {
    const res = await fetch(baseUrl + `fblog/${bid}/post/${postId}/${tbid}`, getHeader)
    return res;
}
export async function addPost(env, blogId, content) {
    const res = await fetch(baseUrl + "post/blog/" + blogId, getHeader)
    return res;
}
export async function getCommentsByBlogId(env, blogId) {
    const { results } = await env.COMMENT.prepare(
        "SELECT * FROM comment where postId=?"
    )
        .bind(blogId)
        .all();
    return results;
}
export async function addComment(env, postId, parentid, title, content, author) {
    const db = await env.COMMENT; // 获取 D1 数据库实例
    const sql = `
        INSERT INTO comment (title, postId, parentid, content, author,created)
        VALUES (?, ?, ?, ?, ?, ?);
      `;
    return await db.prepare(sql).bind(title, postId, parentid, content, author, getUTCTimestap()).run(); // 执行插入操作
}
export function isNotValidToken(token) {
    return token === config.getToken() ? null : new Response('无权操作', { status: 401 });;
}
export function getPostDb(env, blogId) {
    let idx = blogId % 4;
    idx = idx === 0 ? 4 : idx;
    console.log('getPostDb:' + idx);
    return env["POST" + (idx)];
}
export function getUTCTimestap() {
    return new Date().getTime();
}