import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNavs, setActiveNav } from '../reducers/navSlice';
import { useLocation } from 'react-router-dom';
// 创建一个功能组件，用于处理副作用
const PageLoadEffect = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const status = useSelector((state) => state.nav.status);
    useEffect(() => {
        // 检查是否已经有了导航数据，没有就加载
        if (status === "idle") {
            dispatch(fetchNavs());
        }
        //检查当前导航菜单的选中项
        setCurrentActiveNav(dispatch, location);
        console.log('Page loaded');
    }, [dispatch, status, location]);
    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error loading data.</div>;
    }

    return children;
};

function setCurrentActiveNav(dispatch, location) {
    if (!location || !location.pathname) return;
    if (location.pathname.indexOf("/post/") === 0) {
        dispatch(setActiveNav(2));
        return;
    }
    dispatch(setActiveNav(1));
}

// 高阶组件
const withPageLoad = (WrappedComponent) => {
    return (props) => (
        <PageLoadEffect>
            <WrappedComponent {...props} />
        </PageLoadEffect>
    );
};

export default withPageLoad;
