import React from 'react';
import NavHeader from '../components/NavHeader'
import NavFooter from '../components/NavFooter';
import { Outlet } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchNewsContent } from '../reducers/newsSlice';
import AdminSidebar from '../components/AdminSidebar';
// import EditPost from '../components/EditPost';

function Admin() {
    // const { bid } = useParams();
    // const dispatch = useDispatch();
    // const status = useSelector((state) => state.news.status);
    // const newsItem = useSelector((state) => state.news.curNews) || [];
    // console.log("Admin page loaded.")
    // useEffect(() => {
    //     console.log(bid)
    //     dispatch(fetchNewsContent(bid));
    // }, [bid, dispatch]);
    // if (status === 'loading') {
    //     return <div>Loading...</div>;
    // }

    // if (status === 'failed') {
    //     return <div>Error loading data.</div>;
    // }
    return (
        <div>
            <NavHeader></NavHeader>
            <div>这里是管理控制台</div>
            <div className="admin-container">
                <AdminSidebar></AdminSidebar>
                <div className='admin-container-main'>
                    <Outlet />
                </div>
            </div>
            <NavFooter></NavFooter>
        </div>
    );
}

export default Admin;
