import React, { useEffect } from 'react';
import NavHeader from '../components/NavHeader'
import NavFooter from '../components/NavFooter';
import BreadNav from '../components/BreadNav';
import PostSource from '../components/PostSource';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNewsContent } from '../reducers/newsSlice';

function Post() {
    const { pid, tbid, bid } = useParams();
    const dispatch = useDispatch();
    const status = useSelector((state) => state.news.status);
    const newsItem = useSelector((state) => state.news.curNews) || [];
    console.log("post page loaded." + bid)
    useEffect(() => {
        console.log(pid)
        dispatch(fetchNewsContent({ bid, tbid, pid }));
    }, [bid, pid, tbid, dispatch]);
    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error loading data.</div>;
    }
    return (
        <div>
            <NavHeader></NavHeader>
            <BreadNav></BreadNav>
            {/* <div class="post-title" data-bid={bid}>{post.title}aa</div> */}
            <PostSource post={newsItem}></PostSource>
            <div className='post-content'>
                <div>{newsItem.title}</div>
                <div>
                    {newsItem.content}
                </div>
            </div>
            <div>{newsItem.tags}</div>
            <NavFooter></NavFooter>
        </div>
    );
}

export default Post;
