import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import NavHeader from '../components/NavHeader'
import NewsItem from '../components/NewsItem'
import '../App.css';
import NavFooter from '../components/NavFooter';
import { fetchNews } from '../reducers/newsSlice';
function Home() {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.news.status);
    const newsItems = useSelector(state => state.news.items) || [];
    useEffect(() => {
        // 在应用启动时执行的 action
        dispatch(fetchNews());
    }, [dispatch]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error loading data.</div>;
    }
    return (
        <div>
            <NavHeader></NavHeader>
            {
                newsItems.map(n => (<NewsItem key={n.id} id={n.id} pid={n.pid} tbid={n.tbid} desc={n.summary} img={n.img} title={n.title} url={n.url} time={n.time} tags={n.tags} />))
            }
            <NavFooter></NavFooter>
        </div>
    );
}

export default Home;
