// config.js
let config = {
    dev: {
        apiBaseUrl: 'http://localhost:8787/api/',
    },
    prod: {
        apiBaseUrl: 'https://gapi.520131445.xyz/api/',
    },
};
const globalConfig = {
    getToken() {
        return localStorage.getItem("cfblg_tk");
    }
}
const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
config = { ...config[env], ...globalConfig };
export default config;
