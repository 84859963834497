import React from 'react';
import { Link } from 'react-router-dom';

const AdminSidebar = () => {
    return (
        <div className="admin-sidebar">
            <ul >
                <li>
                    <Link to={`/admin/blog/list`}>文章列表</Link>
                </li>
                <li>
                    <Link to={`/admin/blog/new`}>新增文章</Link>
                </li>
            </ul>
        </div>
    );
}

export default AdminSidebar;