import React from 'react';

const PostSource = ({ post }) => {
    post = post || {};
    return (
        <div className="news-item">
            <span>{post.createdTime}</span><span>来源：{post.source}</span><span>作者：{post.author}</span><span>责编：{post.creator}</span><span>评论：{post.comment_count}</span>
            {/* 2024/8/13 17:13:04 来源：IT之家 作者：沛霖（实习） 责编：沛霖评论：23 */}
        </div>

    );
}

export default PostSource;
