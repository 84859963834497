import React from 'react';
import { Link } from 'react-router-dom';

const NewsItemListSimple = ({ newsItems }) => {
    newsItems = newsItems || [];
    return (
        <table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>标题</th>
                    <th>创建时间</th>
                    <th>描述</th>
                    <th>标签</th>
                </tr>
            </thead>
            <tbody>
                {newsItems.map(p => (<tr><td>{p.id}</td>
                    <td> <Link to={`/admin/blog/edit/${p.id}`}>{p.title}</Link></td><td>{p.time}</td>
                    <td>{p.desc}</td><td>{p.tags}</td></tr>)
                )}
            </tbody>
        </table>
    );
}

export default NewsItemListSimple;
