import React from 'react';
import { useSelector } from 'react-redux';

function NavFooter() {
    const navFootItems = useSelector(state => state.nav.footItems) || [];
    return (
        <footer className="App-footer">
            <nav>
                <ul>
                    {navFootItems.map((item) => (
                        <li key={item.id}>
                            <a href={item.url}> {item.desc}</a>&nbsp;&nbsp;<span>|</span></li>
                    ))}
                </ul>
            </nav>
        </footer>
    );

}

export default NavFooter;
