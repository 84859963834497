import { useSelector } from 'react-redux';
function BreadNav() {
    const navFootItems = useSelector(state => state.nav.footItems) || [];
    return (
        <div className="nav-bread">
            <nav>
                <ul>
                    {navFootItems.map((item, index) => (
                        <li key={item.id}>{item.desc}&nbsp;&nbsp;<span>|</span></li>
                    ))}
                </ul>
            </nav>
        </div>
    );

}

export default BreadNav;
