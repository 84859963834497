import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBlogByPage, getFullBlog, addFullBlog } from '../services/api'
import { format } from 'date-fns'

export const fetchNews = createAsyncThunk(
    'nav/fetchNews', // 更新了 typePrefix
    async () => {
        const response = await getBlogByPage(1, 5);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // 解析 JSON 数据
    }
);
export const fetchNewsContent = createAsyncThunk(
    'nav/fetchNewsContent',
    async (request) => {
        const { bid, tbid, pid } = request;
        const response = await getFullBlog(bid, tbid, pid);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // 解析 JSON 数据
    }
);
export const addFullBlogAction = createAsyncThunk(
    'nav/addFullBlog',
    async (blog) => {
        const response = await addFullBlog(blog);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // 解析 JSON 数据
    }
);

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        items: [],//文章列表简介
        curNews: null,//当前阅读的文章内容
        active: null,
        status: 'idle', // 添加状态字段
        error: null // 添加错误字段
    },
    reducers: {
        setActiveNav: (state, action) => {
            state.active = action.payload; // 修正了状态更新逻辑
        },
        setNavHeadItems: (state, action) => {
            state.headItems = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNews.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                let items = action.payload.data;
                items.forEach(item => {
                    if (item.created > 0) {
                        const date = new Date(item.created);
                        item.time = format(date, 'yyyy-MM-dd HH:mm:ss');
                    } else {
                        item.time = "";
                    }
                    item.tags = item.tags ? item.tags.split(',') : [];

                });
                state.items = items;
            })
            .addCase(fetchNews.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.items = [];
            })
            .addCase(fetchNewsContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNewsContent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                let { blog, post } = action.payload.data || {};
                blog = blog || {};
                post = post || {};
                state.curNews = {
                    id: blog.id,
                    title: blog.title,
                    time: blog.modified && blog.modified > 0 ? format(new Date(blog.modified), "yyyy-MM-dd HH:mm:ss") : "",
                    desc: blog.summary, tags: blog.tags,
                    content: post.content, pid: post.pid
                };
            })
            .addCase(fetchNewsContent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.curNews = null;
            }).addCase(addFullBlogAction.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addFullBlogAction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (!action.payload.success) {
                    state.error = action.payload.msg;
                    return;
                }
                let item = action.payload.data || {};
                state.curNews = item;
            })
            .addCase(addFullBlogAction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.curNews = null;
            });
    },
});
export const { setActiveNav, setNavheadItems } = newsSlice.actions;
export default newsSlice.reducer; // 导出 reducer
