import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const fetchNavs = createAsyncThunk(
    'nav/fetchNavs', // 更新了 typePrefix
    async () => {
        const response = await fetch(`/nav.json`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // 解析 JSON 数据
    }
);

const navSlice = createSlice({
    name: 'nav',
    initialState: {
        headItems: [],
        footItems: [],
        active: null,
        status: 'idle', // 添加状态字段
        error: null // 添加错误字段
    },
    reducers: {
        setActiveNav: (state, action) => {
            state.active = action.payload; // 修正了状态更新逻辑
        },
        setNavHeadItems: (state, action) => {
            state.headItems = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNavs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNavs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.headItems = action.payload.navItems;
                state.footItems = action.payload.navFootItems;
            })
            .addCase(fetchNavs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.headItems = [];
            });
    },
});
export const { setActiveNav, setNavheadItems } = navSlice.actions;
export default navSlice.reducer; // 导出 reducer
