import React from 'react';
import { Link } from 'react-router-dom';

const NewsItem = ({ id, tbid, pid, title, time, desc, tags, img, url }) => {
    tags = tags || [];
    return (
        < div className="news-item" >
            <div className="news-item-img">
                <img src={img} alt='' />
            </div>
            <div>
                <Link to={`/blog/${id}/post/${pid}/${tbid}`} state={{ id, title, time, desc, tags, img, url }}><h2>{title}</h2></Link>
                <p>{time}</p>
                <p>{desc}</p>
                <div className="tags">
                    {tags.map(tag => <span key={tag} className='tag'>{tag}</span>)}
                </div>
            </div>
        </div >
    );
}

export default NewsItem;
